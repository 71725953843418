import format from 'date-fns/format';
import React, { useCallback, useEffect, useId } from 'react';
import { DEFAULTS } from '../../../constants/Constants';
import { BaseWidget } from '../layout/View';

export type ClockWidget = BaseWidget & {
  type: 'clock';
  clock?: {
    elementId?: string;
    format?: string;
    interval?: number;
    style?: string;
  };
};

const Clock: React.FC<{ options: ClockWidget['clock'] }> = ({ options = {} }) => {
  const [time, setTime] = React.useState(' ');
  const intervalRef = React.useRef<number | null>(null);

  const tick = useCallback(() => {
    const time = format(new Date(), options.format || DEFAULTS.WIDGET_CLOCK_FORMAT);
    setTime(time);
  }, [options.format]);

  const reset = useCallback(() => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }

    intervalRef.current = window.setInterval(tick, options.interval || DEFAULTS.WIDGET_CLOCK_INTERVAL);
  }, [options.interval, tick]);

  useEffect(() => {
    reset();
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [reset]);

  const defaultId = useId();

  const hasElementId = options.elementId && options.elementId !== '';
  const elementId = hasElementId ? options.elementId! : defaultId;

  const defaultStyle = `
    #${CSS.escape(elementId)}--container {
      display: flex;
      justify-items: center;
      align-items: center;
      width: 100%;
      height: 100%;
    }
    #${CSS.escape(elementId)} {
      font-size: 22px;
      flex-grow: 1;
      text-align: center;
    }
  `;
  const hasStyle = options.style && options.style !== '';
  const style = hasStyle ? options.style! : defaultStyle;

  return <div id={`${elementId}--container`}>
    <style dangerouslySetInnerHTML={{ __html: style as string }}></style>
    <div id={elementId}>{time}</div>
  </div>;
};

export default Clock;
