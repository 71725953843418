import React, { useEffect } from 'react';
import LoadingIndicator from '../components/LoadingIndicator';
import DashboardContainer from './DashboardContainer';
import { Callout } from '@blueprintjs/core';
import CenterPage from '../components/CenterPage';
import { useDashboard } from '../lib/api.ts';
import { useParams } from 'react-router';
import { useDocumentTitle } from '@uidotdev/usehooks';

const DirectDashboardContainer: React.FC = () => {
  const id = useParams<{ id: string }>().id!;
  const { data, isPending } = useDashboard(id);

  useEffect(() => {
    const cname = data?.theme === 'light' ? 'bp5-light' : 'bp5-dark';
    document.body.classList.add(cname);
    return () => {
      document.body.classList.remove(cname);
    };
  }, [data?.theme]);

  useDocumentTitle(data?.title || `Dashboard ${id}`);

  if (isPending) {
    return (
      <CenterPage>
        <LoadingIndicator />
      </CenterPage>
    );
  }

  return data ? (
    <DashboardContainer dashboard={data} />
  ) : (
    <CenterPage>
      <Callout intent="danger" title="Error" style={{ maxWidth: 320 }}>
        Dashboard does not exist.
      </Callout>
    </CenterPage>
  );
};

export default DirectDashboardContainer;
