import React, { ReactNode, useMemo } from 'react';
import { normalizeObject } from '../../../lib/helpers';
import Widget, { IWidget } from './Widget';
import { DynamicObject } from '../layout/View';

const WidgetBlock: React.FC<{
  vertical: string;
  horizontal: string;
  children?: ReactNode;
  id: string;
}> = ({ vertical, horizontal, children, id }) => {
  const alignItems = (v: string) => {
    switch (v) {
      case 'middle':
        return 'center';
      case 'bottom':
        return 'flex-end';
      default:
        return 'flex-start';
    }
  };

  const justifyContent = (h: string) => {
    switch (h) {
      case 'center':
        return 'center';
      case 'right':
        return 'flex-end';
      default:
        return 'flex-start';
    }
  };

  const style = {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: alignItems(vertical),
    justifyContent: justifyContent(horizontal)
  } as const;
  return (
    <div style={style} id={id}>
      {children}
    </div>
  );
};

const WidgetHelper = {
  row: (positionVertical: string | undefined) => {
    switch (positionVertical) {
      case 'middle':
        return 'middle';
      case 'bottom':
        return 'bottom';
      default:
        return 'top';
    }
  },
  col: (positionHorizontal: string | undefined) => {
    switch (positionHorizontal) {
      case 'center':
        return 'center';
      case 'right':
        return 'right';
      default:
        return 'left';
    }
  }
};

const WidgetContainer: React.FC<{ widgets: DynamicObject<IWidget> }> = ({ widgets }) => {
  const cfg = useMemo(() => normalizeObject(widgets), [widgets]);

  const ac = Object.entries(cfg).reduce<Record<string, Record<string, IWidget>>>((acc, [key, widget]) => {
    const groupKey = `${widget.positionVertical}-${widget.positionHorizontal}`;
    acc[groupKey] = acc[groupKey] || {};
    acc[groupKey][key] = widget;
    return acc;
  }, {});

  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        pointerEvents: 'none'
      }}
    >
      {Object.entries(ac).map(([key, widgets]) => (
        <WidgetBlock
          id={key}
          key={key}
          horizontal={WidgetHelper.col(key.split('-')[1])}
          vertical={WidgetHelper.row(key.split('-')[0])}
        >
          {Object.entries(widgets).map(([key, widget]) => (
            <Widget key={key} widget={widget} id={key} />
          ))}
        </WidgetBlock>
      ))}
    </div>
  );
};

export default WidgetContainer;
