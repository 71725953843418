import { H3 } from '@blueprintjs/core';
import { differenceInMilliseconds } from 'date-fns';
import React, { useCallback, useEffect } from 'react';
import { DEFAULTS } from '../../../constants/Constants';
import { formatDuration } from '../../../lib/helpers';
import { BaseWidget } from '../layout/View';

export type TimerWidget = BaseWidget & {
  type: 'timer';
  timer?: {
    countdown?: boolean;
    format?: string;
    target?: string;
    interval?: number;
    finishMessage?: string;
  };
};

const Timer: React.FC<{ options: TimerWidget['timer'] }> = ({ options = {} }) => {
  const [time, setTime] = React.useState(' ');
  const intervalRef = React.useRef<number | null>(null);
  const startDateRef = React.useRef<Date | null>(null);
  const countdownRef = React.useRef<boolean | null>(null);

  const tick = useCallback(() => {
    const a = countdownRef.current ? startDateRef.current : new Date();
    const b = countdownRef.current ? new Date() : startDateRef.current;

    if (a === null || b === null) return;

    const d = differenceInMilliseconds(a, b);

    let time = '';

    if (d < 0) {
      // timer is done
      // stop interval and display result message
      if (intervalRef.current != null) {
        clearInterval(intervalRef.current);
      }
      time = options.finishMessage || DEFAULTS.WIDGET_TIMER_FINISH_MESSAGE;
    } else {
      // timer is not done, format time
      time = formatDuration(d, options.format || DEFAULTS.WIDGET_TIMER_FORMAT);
    }
    setTime(time);
  }, [options.format, options.finishMessage]);

  const reset = useCallback(() => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }

    startDateRef.current = new Date(options.target ?? '');
    countdownRef.current = options.countdown === undefined ? startDateRef.current > new Date() : !!options.countdown;

    intervalRef.current = window.setInterval(tick, options.interval || DEFAULTS.WIDGET_CLOCK_INTERVAL);
  }, [options.interval, options.countdown, options.target, tick]);

  useEffect(() => {
    reset();
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [reset]);

  return <H3 style={{ textAlign: 'center' }}>{time}</H3>;
};

export default Timer;
