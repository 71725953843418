import React from 'react';
import PropTypes from 'prop-types';
import { H5, Card, Elevation } from '@blueprintjs/core';

import View, { TView } from '../layout/View';

export interface IWidget {
  positionVertical?: string;
  positionHorizontal?: string;
  label?: { title?: string };
  background?: boolean;
  view?: TView;
}

const WidgetLabel: React.FC<{ label?: { title?: string } }> = ({ label }) => {
  if (!label) return null;

  const { title, ...props } = label;

  return (
    <H5 style={{ textAlign: 'center' }} {...props}>
      {title}
    </H5>
  );
};

WidgetLabel.propTypes = {
  label: PropTypes.object
};

const Widget: React.FC<{ widget: IWidget; id: string }> = ({ widget, id }) => {
  const style = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  } as const;

  const layout = () => (
    <React.Fragment>
      <WidgetLabel label={widget.label} />
      {widget.view && <View view={widget.view} />}
    </React.Fragment>
  );

  if (widget.background) {
    return (
      <Card elevation={Elevation.TWO} style={{ ...style, minWidth: '200px' }} id={id}>
        {layout()}
      </Card>
    );
  } else {
    return <div style={style}>{layout()}</div>;
  }
};

export default Widget;
