import React from 'react';
import { DashboardData } from 'types';
import View from './components/layout/View';
import WidgetContainer from './components/widgets/WidgetContainer';

const DashboardContainer: React.FC<{ dashboard: DashboardData }> = ({ dashboard }) => {
  if (!dashboard) return null;

  const { backgroundColor, configuration } = dashboard;
  const { view, widgets } = configuration || {};

  return (
    <div
      className="dashboardContainer"
      style={{
        width: '100vw',
        height: '100vh',
        overflow: 'hidden',
        backgroundColor,
      }}
    >
      {configuration ? (
        <React.Fragment>
          {view && <View view={view} />}
          {widgets && <WidgetContainer widgets={widgets} />}
        </React.Fragment>
      ) : (
        <h1 style={{ textAlign: 'center', color: 'white' }}>Configuration is empty</h1>
      )}
    </div>
  );
};

export default DashboardContainer;
