import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { DEFAULTS } from '../../../constants/Constants';
import { normalizeObject } from '../../../lib/helpers';
import View, { BaseWidget, TView } from '../layout/View';

export type ViewSliderWidget = BaseWidget & {
  type: 'viewSlider';
  viewSlider?: {
    interval?: number;
    views?: Record<string, TView>;
  };
};

const ViewSlider: React.FC<{ options: ViewSliderWidget['viewSlider'] }> = ({ options }) => {
  const views = useMemo(() => normalizeObject(options?.views), [options?.views]);
  const viewsCount = Object.keys(views).length;

  const wrapperRef = useRef<HTMLDivElement>(null);
  const indexRef = useRef(0);
  const stepIntervalRef = useRef<number | null>(null);

  const step = useCallback(() => {
    indexRef.current = (indexRef.current + 1) % viewsCount;
    if (wrapperRef.current) {
      wrapperRef.current.scrollLeft = indexRef.current * wrapperRef.current.clientWidth;
    }
  }, [viewsCount]);

  useEffect(() => {
    if (stepIntervalRef.current) {
      clearInterval(stepIntervalRef.current);
    }

    stepIntervalRef.current = window.setInterval(step, (options?.interval ?? DEFAULTS.SCREENS_INTERVAL) * 1000);

    return () => {
      if (stepIntervalRef.current) {
        clearInterval(stepIntervalRef.current);
      }
    };
  }, [options?.interval, step]);

  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        overflow: 'hidden',
      }}
      ref={wrapperRef}
    >
      <div
        style={{
          height: '100%',
          width: `${viewsCount * 100}%`,
          display: 'flex',
        }}
      >
        {Object.entries(views).map(([key, view]) => (
          <div
            key={key}
            id={`slider_${key}`}
            style={{
              float: 'left',
              width: '100%',
              height: '100%',
              position: 'relative',
            }}
          >
            <View view={view} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ViewSlider;
