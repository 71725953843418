import { BrowserRouter, Route, Routes } from 'react-router-dom';

import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/datetime2/lib/css/blueprint-datetime2.css';
import '@blueprintjs/icons/lib/css/blueprint-icons.css';
import 'normalize.css/normalize.css';
import './Display.scss';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import DirectDashboardContainer from '../client/DirectDashboardContainer.tsx';
import DisplayContainer from '../client/DisplayContainer.tsx';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

const Display = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<DisplayContainer />} />
          <Route path="/dashboards/:id" element={<DirectDashboardContainer />} />
        </Routes>
      </BrowserRouter>
    </QueryClientProvider>
  );
};

export default Display;
