import React from 'react';
import MarkdownRenderer from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { BaseWidget } from '../layout/View';

export type MarkdownWidget = BaseWidget & {
  type: 'markdown';
  markdown?: {
    markdown?: string;
  };
};

const Markdown: React.FC<{ options: MarkdownWidget['markdown'] }> = ({ options = {} }) => (
  <div
    style={{ position: 'relative', width: '100%', height: '100%', padding: '10px', overflow: 'auto' }}
    className="bp5-running-text"
  >
    <MarkdownRenderer remarkPlugins={[remarkGfm]}>{options.markdown}</MarkdownRenderer>
  </div>
);

export default Markdown;
