import React from 'react';
import { BaseWidget } from '../layout/View';

export type TextWidget = BaseWidget & {
  type: 'text';
  text?: {
    text?: string;
  };
};

const Text: React.FC<{ options: TextWidget['text'] }> = ({ options = {} }) => <p>{options.text}</p>;

export default Text;
