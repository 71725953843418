import React from 'react';
import { BaseWidget } from '../layout/View';

export type IFrameWidget = BaseWidget & {
  type: 'iframe';
  iframe?: {
    url?: string;
    interval?: number;
  };
};

const Iframe: React.FC<{ options: IFrameWidget['iframe'] }> = ({ options = {} }) => {
  const frameRef = React.useRef<HTMLIFrameElement>(null);

  React.useEffect(() => {
    if (options.interval) {
      const intval = setInterval(() => {
        if (frameRef.current) {
          // eslint-disable-next-line no-self-assign
          frameRef.current.src = frameRef.current.src;
        }
      }, options.interval * 1000);
      return () => {
        clearInterval(intval);
      };
    }
  }, [options]);

  return (
    <iframe
      style={{
        width: '100%',
        height: '100%',
        border: 0,
        position: 'absolute',
      }}
      src={options.url}
      scrolling="no"
      ref={frameRef}
    />
  );
};

export default Iframe;
