import { Callout } from '@blueprintjs/core';
import { useDocumentTitle } from '@uidotdev/usehooks';
import React, { useEffect } from 'react';
import CenterPage from '../components/CenterPage';
import LoadingIndicator from '../components/LoadingIndicator';
import { useDisplayDashboard } from '../lib/api.ts';
import DashboardContainer from './DashboardContainer';
import { useSerialNumber } from './hooks/useSerialNumber.ts';

const DisplayContainer: React.FC = () => {
  const serialNumber = useSerialNumber();

  const { data, isPending, error } = useDisplayDashboard(serialNumber);

  useEffect(() => {
    const cname = data?.theme === 'light' ? 'bp5-light' : 'bp5-dark';
    document.body.classList.add(cname);
    return () => {
      document.body.classList.remove(cname);
    };
  }, [data?.theme]);

  useDocumentTitle(data?.title || `Dashboard for ${serialNumber}`);

  if (isPending) {
    return (
      <CenterPage>
        <LoadingIndicator />
      </CenterPage>
    );
  }

  return data ? (
    <DashboardContainer dashboard={data} />
  ) : (
    <CenterPage>
      <Callout intent="primary" title="Info" style={{ maxWidth: 460 }}>
        No dashboard configured for this display.
        <div className="bp5-monospace-text bp5-text-large" style={{ marginTop: '2em' }}>
          {serialNumber}
        </div>
      </Callout>
      {error && (
        <Callout intent="danger" title="Error" style={{ marginTop: '1em', maxWidth: 460 }}>
          <p className="bp5-text-large" style={{ marginBottom: 0 }}>
            {error.message}
          </p>
        </Callout>
      )}
    </CenterPage>
  );
};

export default DisplayContainer;
