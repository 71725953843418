import React from 'react';
import { BaseWidget } from '../layout/View';

export type HTMLWidget = BaseWidget & {
  type: 'html';
  html?: {
    html?: string;
  };
};

const Html: React.FC<{ options: HTMLWidget['html'] }> = ({ options = {} }) => (
  <div
    style={{ position: 'relative', width: '100%', height: '100%' }}
    dangerouslySetInnerHTML={{ __html: options.html || '' }}
  />
);

export default Html;
