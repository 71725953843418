import React, { useEffect, useId, useRef } from 'react';
import Reveal from 'reveal.js';
import { DEFAULTS } from '../../../constants/Constants';
import { BaseWidget } from '../layout/View';

export type SlideshowWidget = BaseWidget & {
  type: 'slideshow';
  slideshow?: {
    interval?: number;
    images?: string[];
  };
};

const Slideshow: React.FC<{ options: SlideshowWidget['slideshow'] }> = ({ options }) => {
  const id = useId();
  const images = options?.images ?? [];
  const interval = options?.interval ?? DEFAULTS.WIDGET_SLIDESHOW_INTERVAL;

  const revealRef = useRef<Reveal.Api>();

  useEffect(() => {
    const options: Reveal.Options = {
      transition: 'fade',
      embedded: true,
      autoSlide: interval * 1000,
      autoSlideStoppable: false,
      loop: true,
      controls: false,
      progress: false,
    };
    if (revealRef.current == null) {
      const deck = document.getElementById(id);
      if (deck) {
        revealRef.current = new Reveal(deck, options);
        revealRef.current.initialize();
      }
    } else {
      revealRef.current.configure(options);
    }
  }, [id, interval]);

  return (
    <div
      style={{
        height: '100%',
        width: '100%',
      }}
    >
      <div className="reveal" id={id}>
        <div className="slides">
          {images.map((image, i) => (
            <section key={i} style={{ width: '100%', height: '100%' }}>
              <img data-src={image} style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
            </section>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Slideshow;
