import * as React from 'react';

interface IProps {
  children?: React.ReactNode;
}

const CenterPage: React.FC<IProps> = ({ children }) => (
  <div
    className="bp5-dark"
    style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100vw',
      height: '100vh',
    }}
  >
    {children}
  </div>
);

export default CenterPage;
