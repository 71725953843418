import { parse } from 'csv/browser/esm/sync';
import { compile, TemplateFunction } from 'ejs';
import moment from 'moment';
import React, { useCallback, useEffect } from 'react';
import { useSerialNumber } from '../../hooks/useSerialNumber.ts';
import { BaseWidget } from '../layout/View.tsx';

export type TemplateWidget = BaseWidget & {
  type: 'template';
  template?: {
    template?: string;
    interval?: number;
    dataUrl?: string;
  };
};

const parseCsv = parse;

const Template: React.FC<{ options: TemplateWidget['template'] }> = ({ options = {} }) => {
  const serialNumber = useSerialNumber();

  const [data, setData] = React.useState<any>(null);
  const [compiledTemplate, setCompiledTemplate] = React.useState<TemplateFunction | null>(null);

  const fetchData = useCallback(async () => {
    if (options.dataUrl) {
      try {
        const response = await fetch(options.dataUrl);
        const data = await response.text();
        setData(data);
      } catch (e) {
        console.error('Failed to fetch data', e);
      }
    } else {
      setData(null);
    }
  }, [options.dataUrl]);

  useEffect(() => {
    if (options.interval) {
      fetchData();

      const timer = setInterval(fetchData, (options.interval || 30) * 1000);
      return () => clearInterval(timer);
    } else {
      fetchData();
    }
  }, [options.interval, fetchData]);

  useEffect(() => {
    if (options.template) {
      try {
        const compiled = compile(options.template, {
          client: true,
          async: false,
        });
        setCompiledTemplate(() => compiled);
      } catch (e) {
        console.error('Failed to compile template', e);
        setCompiledTemplate(null);
      }
    } else {
      setCompiledTemplate(null);
    }
  }, [options.template]);

  let content = null;

  if (compiledTemplate) {
    try {
      content = compiledTemplate({ serialNumber, data, parseCsv, moment });
    } catch (e) {
      console.error('Failed to render template', e);
    }
  }

  return (
    <div
      style={{
        position: 'relative',
        width: '100%',
        height: '100%',
        overflow: 'auto',
      }}
      dangerouslySetInnerHTML={{ __html: content ?? '' }}
    />
  );
};

export default Template;
