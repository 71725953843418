import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import Display from '../components/Display';

const root = document.getElementById('display');

if (root != null) {
  createRoot(root).render(
    <StrictMode>
      <Display />
    </StrictMode>
  );
}
