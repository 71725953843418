import React from 'react';
import { isLayoutView } from '../../../lib/helpers';
import Clock, { ClockWidget } from '../widgets/Clock';
import Html, { HTMLWidget } from '../widgets/Html';
import Iframe, { IFrameWidget } from '../widgets/Iframe';
import Markdown, { MarkdownWidget } from '../widgets/Markdown.tsx';
import Slideshow, { SlideshowWidget } from '../widgets/Slideshow.tsx';
import Template, { TemplateWidget } from '../widgets/Template.tsx';
import Text, { TextWidget } from '../widgets/Text';
import Timer, { TimerWidget } from '../widgets/Timer';
import ViewSlider, { ViewSliderWidget } from '../widgets/ViewSlider';

export type HorizontalView = {
  layout: 'row';
  fraction?: string;
  view0: TView;
  view1: TView;
};

export type VerticalView = {
  layout: 'column';
  fraction?: string;
  view0: TView;
  view1: TView;
};

export type BaseWidget = {
  layout: '';
};

type EmptyWidget = BaseWidget & {
  type: '';
};

export type DynamicObject<T> = {
  [key: string]: T;
};

export type TView =
  | VerticalView
  | HorizontalView
  | EmptyWidget
  | IFrameWidget
  | TimerWidget
  | TextWidget
  | HTMLWidget
  | MarkdownWidget
  | TemplateWidget
  | ClockWidget
  | ViewSliderWidget
  | SlideshowWidget;

const View: React.FC<{ view: TView }> = ({ view = { type: '' } }) => {
  if (isLayoutView(view)) {
    const fractionValue = view.fraction?.toString().trim();
    let fraction: string = fractionValue == null || fractionValue === '' ? '50%' : fractionValue;
    if (!isNaN(Number(fraction))) {
      const f = Number(fraction);
      if (f < 1) {
        fraction = f * 100 + '%';
      } else {
        fraction = f + 'px';
      }
    } else if (!fraction.match(/^\d+(px|%)$/)) {
      fraction = '50%';
    }
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: view.layout,
          width: '100%',
          height: '100%',
          overflow: 'hidden',
        }}
      >
        <div
          style={{
            position: 'relative',
            [view.layout === 'column' ? 'height' : 'width']: fraction,
          }}
        >
          <View view={view.view0} />
        </div>
        <div
          style={{
            flex: 1,
            position: 'relative',
          }}
        >
          <View view={view.view1} />
        </div>
      </div>
    );
  } else {
    // No layout, just display content
    const content = () => {
      switch (view.type) {
        case 'text':
          return <Text options={view.text} />;
        case 'html':
          return <Html options={view.html} />;
        case 'markdown':
          return <Markdown options={view.markdown} />;
        case 'template':
          return <Template options={view.template} />;
        case 'timer':
          return <Timer options={view.timer} />;
        case 'clock':
          return <Clock options={view.clock} />;
        case 'iframe':
          return <Iframe options={view.iframe} />;
        case 'viewSlider':
          return <ViewSlider options={view.viewSlider} />;
        case 'slideshow':
          return <Slideshow options={view.slideshow} />;
        default:
          return null;
      }
    };

    return <div style={{ width: '100%', height: '100%' }}>{content()}</div>;
  }
};

export default View;
