import { useQuery } from '@tanstack/react-query';
import { useSearchParams } from 'react-router-dom';

function buildBrowserInfo() {
  try {
    const browserInfo = new URLSearchParams();
    browserInfo.append('browser_info[width]', window.innerWidth.toString());
    browserInfo.append('browser_info[height]', window.innerHeight.toString());
    browserInfo.append('browser_info[device_pixel_ratio]', window.devicePixelRatio.toString());
    browserInfo.append(
      'browser_info[theme]',
      window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light'
    );
    browserInfo.append('browser_info[language]', navigator.language);
    browserInfo.append('browser_info[timezone]', Intl.DateTimeFormat().resolvedOptions().timeZone);
    browserInfo.append('browser_info[screen_width]', window.screen.width.toString());
    browserInfo.append('browser_info[screen_height]', window.screen.height.toString());
    browserInfo.append('browser_info[screen_color_depth]', window.screen.colorDepth.toString());
    browserInfo.append('browser_info[screen_orientation]', window.screen.orientation?.type ?? 'unknown');
    return browserInfo;
  } catch (error: unknown) {
    let message = 'Could not build browser info';
    if (error instanceof Error) {
      message += ': ' + error.message;
    }
    return 'browser_info[error]=true&browser_info[message]=' + encodeURIComponent(message);
  }
}

export const useDisplayDashboard = (serialNumber: string) => {
  const [searchParams] = useSearchParams();
  const refetchInterval = parseInt(searchParams.get('interval') || '5000');

  return useQuery({
    refetchInterval,
    queryKey: ['displays', serialNumber, 'dashboard'],
    queryFn: async () => {
      const response = await fetch(`/api/displays/${serialNumber}/dashboard?` + buildBrowserInfo());
      if (!response.ok) {
        if (response.status === 404) {
          return null;
        }

        throw new Error('Could not fetch display dashboard');
      }
      return response.json();
    },
  });
};

export const useDashboard = (id: string | null) => {
  const [searchParams] = useSearchParams();
  const refetchInterval = parseInt(searchParams.get('interval') || '5000');

  return useQuery({
    refetchInterval,
    queryKey: ['dashboards', id],
    queryFn: async () => {
      const response = await fetch(`/api/dashboards/${id}?` + buildBrowserInfo());
      if (!response.ok) {
        if (response.status === 404) {
          return null;
        }

        throw new Error('Could not fetch dashboard');
      }
      return response.json();
    },
  });
};
