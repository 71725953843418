import { useLocalStorage } from '@uidotdev/usehooks';
import { useSearchParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

export const useSerialNumber = () => {
  const defaultSerialNumber = uuidv4();

  const [searchParams] = useSearchParams();
  const forceSerialNumber = searchParams.get('sn');

  const [serialNumber] = useLocalStorage('serialNumber', defaultSerialNumber);

  return forceSerialNumber ? forceSerialNumber : serialNumber;
};
